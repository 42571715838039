"use client";

import { useScopedGsapContext } from "@/component-library/animation/gsap/useScopedGsapContext";
import { popperStyle } from "@/component-library/components/layout/popper/PopperStyles.css";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { remToPx } from "@/component-library/utilities/font-size-converters";
import { useViewportDimensionsChanged } from "@/component-library/utilities/useViewportDimensionsChanged";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import gsap from "gsap";
import React, {
    forwardRef,
    HTMLAttributes,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { useIsomorphicLayoutEffect } from "usehooks-ts";

interface PopperProps extends HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    popperContents: React.ReactNode;
    triggerOnHover?: boolean;
    triggerOnMouseDown?: boolean;
    alwaysShow?: boolean;
    hoverToggleDelay?: number;
    marginTop?: number;
    marginLeft?: number;
}

/**
 * Creates a popper, i.e. a small overlay that fades into view when triggered.
 * It's for informational, non-critical communication.
 * @param children - The content under which the popper should appear.
 * @param popperContents - What the contents of the popper are. Consider using
 *   existing styles like [DefaultPopperContents].
 * @param triggerOnMouseDown - Whether the popper should be shown when the
 *   children are clicked.
 * @param triggerOnHover - Whether the popper should be shown when the children
 *   are hovered.
 * @param alwaysShow - Whether to always show the popper. If [alwaysShow] is
 *   set, it overrides other trigger methods. Use this when you want full
 *   control of the popper in the parent.
 * @param marginTop - How much space between the bottom of the wrapped contents
 *   and the top of the popper.
 * @param marginLeft - How much space between the left of the wrapped contents
 *   and the left of the popper.
 * @param restProps - Other ways to customise the popper, which is a [div]
 *   element.
 * @param refIn - Both [popperRef] (for the popper) and the
 *   [wrapperContainerRef] (for the wrapper that is observed for location and
 *   triggering phenomena) are exposed.
 */
const Popper = forwardRef<
    {
        popperRef: HTMLDivElement;
        contentWrapperRef: HTMLDivElement;
    },
    PopperProps
>(
    (
        {
            children,
            popperContents,
            triggerOnMouseDown,
            triggerOnHover,
            alwaysShow,
            marginTop,
            marginLeft,
            ...restProps
        },
        refIn
        // eslint-disable-next-line sonarjs/cognitive-complexity
    ) => {
        const wrapperRef = useRef<HTMLDivElement | null>(null);
        const popperRef = useRef<HTMLDivElement | null>(null);

        const context = useScopedGsapContext(popperRef);

        const [isShowing, setIsShowing] = useState(false);

        const [top, setTop] = useState(0);
        const [left, setLeft] = useState(0);

        const { indicator: viewportSizeChangeIndicator } =
            useViewportDimensionsChanged();

        useImperativeHandle(
            refIn,
            () => ({
                popperRef: popperRef.current!,
                contentWrapperRef: wrapperRef.current!,
            }),
            [popperRef]
        );

        useIsomorphicLayoutEffect(() => {
            if (!wrapperRef.current) {
                return;
            }

            setTop(
                wrapperRef.current.offsetTop +
                    wrapperRef.current.clientHeight +
                    (marginTop ?? remToPx(Magnitudes.spacingInRem.xs))
            );
            setLeft(
                wrapperRef.current.offsetLeft +
                    (marginLeft ?? Magnitudes.spacingInRem.xs)
            );
        }, [
            wrapperRef.current,
            viewportSizeChangeIndicator,
            popperRef.current,
            isShowing,
            alwaysShow,
        ]);

        useIsomorphicLayoutEffect(() => {
            context.add(() => {
                const nextIsShowing = alwaysShow ? true : isShowing;

                if (!isShowing && nextIsShowing) {
                    gsap.set(popperRef.current, {
                        onStart: () => {
                            setIsShowing(true);
                        },
                        opacity: 0,
                    });
                } else {
                    setIsShowing(nextIsShowing);
                }

                const timeline = gsap.timeline({});

                timeline.set(popperRef.current, {
                    display: "block",
                });
                timeline.to(popperRef.current, {
                    opacity: nextIsShowing ? 1 : 0,
                    duration: Magnitudes.durationsInS.m,
                });

                if (!nextIsShowing) {
                    timeline.set(popperRef.current, {
                        display: "none",
                    });
                }
            });
        }, [popperRef, alwaysShow, isShowing]);

        return (
            <>
                <div
                    ref={wrapperRef}
                    onMouseMove={
                        triggerOnHover
                            ? () => {
                                  if (isShowing) {
                                      return;
                                  }
                                  setIsShowing(true);
                              }
                            : undefined
                    }
                    onMouseLeave={
                        triggerOnHover
                            ? () => {
                                  setIsShowing(false);
                              }
                            : undefined
                    }
                    onClick={
                        triggerOnMouseDown
                            ? () => {
                                  setIsShowing(true);
                              }
                            : undefined
                    }
                    onMouseDown={
                        triggerOnMouseDown
                            ? () => {
                                  setIsShowing(true);
                              }
                            : undefined
                    }
                    // On Tab press, close the popper
                    onKeyDown={(event) =>
                        event.key === "Tab" && setIsShowing(false)
                    }
                >
                    {children}
                </div>
                <div
                    ref={popperRef}
                    className={popperStyle}
                    style={assignInlineVars({
                        top: `${top}px`,
                        left: `${left}px`,
                    })}
                    key="popper"
                    {...restProps}
                >
                    {popperContents}
                </div>
            </>
        );
    }
);
Popper.displayName = "Popper";

export default Popper;
