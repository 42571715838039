"use client";

import gsap from "gsap";
import { RefObject, useMemo, useRef } from "react";
import { useIsomorphicLayoutEffect } from "usehooks-ts";

/**
 * Provides scoped GSAP context. Animations will be scoped to (the children of)
 * the [scope], and when the [scope] ref goes out of scope or changes,
 * animations will be cleaned up automatically. When the component unmounts,
 * [scope] goes out of scope, and all animations along with the context will be
 * destroyed. **Prefer, [useAnimation]** which provides scoped context and
 * timeline, and other utilities.
 * @param scope - Within which subtree the animations will be limited to.
 */
export const useScopedGsapContext = (scope: RefObject<any> | null) => {
    const scopeRef = useRef(scope);

    /**
     * Create new context whenever the scope changes.
     */
    const context = useMemo(() => {
        return gsap.context(() => {}, scopeRef.current?.current);
    }, [scopeRef]);

    useIsomorphicLayoutEffect(() => {
        /**
         * Always perform animation cleanup when scope changes as well as when
         * the component unmounts, to avoid memory leaks.
         */
        return () => context.revert();
    }, [context, scopeRef]);

    return context;
};
