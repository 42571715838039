import { activeStyle } from "@/component-library/components/activeStyle.css";
import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import {
    checkboxIconRecipe,
    checkboxRecipe,
} from "@/component-library/components/user-input/checkbox/CheckboxStyles.css";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { clsx } from "clsx";
import React, { FC, HTMLAttributes, useRef } from "react";

interface CheckboxProps extends HTMLAttributes<HTMLInputElement> {
    name: string;
    required?: boolean;
    checked: boolean;
}

/**
 * A custom checkbox with animations on (un)checked.
 */
export const Checkbox: FC<CheckboxProps> = ({
    name,
    checked,
    required,
    onChange,
    className,
    ...restProps
}) => {
    const checkboxRef = useRef<HTMLDivElement | null>(null);

    const iconRef = useRef<SVGSVGElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    return (
        <div
            className={clsx(
                checkboxRecipe({
                    variant: checked ? "active" : "inactive",
                }),
                checked && activeStyle,
                className
            )}
            ref={checkboxRef}
        >
            <VisuallyHidden>
                {checked ? "Checkbox is checked" : "Checkbox is unchecked"}
            </VisuallyHidden>
            <input
                ref={inputRef}
                name={name}
                type="checkbox"
                checked={checked}
                required={required}
                onChange={(event) => {
                    onChange?.(event);
                }}
                {...restProps}
            />
            <FontAwesomeIcon
                titleId={"Checkbox Icon"}
                title="Checkbox is checked"
                className={checkboxIconRecipe({
                    variant: checked ? "active" : "inactive",
                })}
                ref={iconRef}
                icon={faCheck}
                aria-hidden={true}
                fontSize={1.6}
            />
        </div>
    );
};
