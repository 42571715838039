"use client";

import { useScopedGsapContext } from "@/component-library/animation/gsap/useScopedGsapContext";
import { useValueRef } from "@/component-library/animation/useValueRef";
import gsap from "gsap";
import { RefObject, useCallback, useMemo } from "react";

export enum AnimationFlag {
    initialised = "initialised",
    entering = "entering",
    animating = "animating",
    exiting = "exiting",
}

/**
 * Provides animation utilities. Cleans up after itself.
 * @param scope within which to scope the animations and animation context.
 * @param scopedTimelineVars - Vars to apply to the provided timeline.
 */
export const useAnimation = (
    scope: RefObject<Element | null> | null,
    scopedTimelineVars?: gsap.TimelineVars
) => {
    const {
        ref: isInitialisedValueRef,
        set: setIsInitialisedValue,
        // state: isInitialised,
    } = useValueRef(false);
    const {
        ref: isEnteringValueRef,
        set: setIsEnteringValue,
        // state: isEntering,
    } = useValueRef(false);
    const { ref: isAnimatingValueRef, set: setIsAnimatingValue } =
        useValueRef(false);

    const {
        ref: isExitingValueRef,
        set: setIsExitingValue,
        //state: isExiting
    } = useValueRef(false);
    const context = useScopedGsapContext(scope);
    const scopedTimeline = gsap.timeline(scopedTimelineVars);

    const getAnimStateFor = useCallback(
        (flag: AnimationFlag) => {
            switch (flag) {
                case AnimationFlag.initialised: {
                    return isInitialisedValueRef.current;
                }
                case AnimationFlag.entering: {
                    return isEnteringValueRef.current;
                }
                case AnimationFlag.animating: {
                    return isAnimatingValueRef.current;
                }
                case AnimationFlag.exiting: {
                    return isExitingValueRef.current;
                }
            }
        },
        [
            isAnimatingValueRef,
            isEnteringValueRef,
            isExitingValueRef,
            isInitialisedValueRef,
        ]
    );
    const setAnimStateFor = useCallback(
        (flag: AnimationFlag, value: boolean) => {
            switch (flag) {
                case AnimationFlag.initialised: {
                    setIsInitialisedValue(value);
                    break;
                }
                case AnimationFlag.entering: {
                    setIsEnteringValue(value);
                    break;
                }
                case AnimationFlag.animating: {
                    setIsAnimatingValue(value);
                    break;
                }
                case AnimationFlag.exiting: {
                    setIsExitingValue(value);
                    break;
                }
            }
        },
        [
            setIsAnimatingValue,
            setIsEnteringValue,
            setIsExitingValue,
            setIsInitialisedValue,
        ]
    );

    return useMemo(
        () => ({
            getAnimStateFor,
            setAnimStateFor,
            context,
            scopedTimeline,
        }),
        [context, getAnimStateFor, scopedTimeline, setAnimStateFor]
    );
};
