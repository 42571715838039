"use client";

import Popper from "@/component-library/components/layout/popper/Popper";
import { errorMessageTextStyle } from "@/component-library/components/text/error-message-styles.css";
import {
    formEntryPopperContents,
    formEntryStyle,
} from "@/component-library/components/user-input/forms/form-entry/FormEntryStyles.css";
import {
    checkboxLabelStyle,
    labelStyle,
} from "@/component-library/components/user-input/label-styles.css";
import { stylex } from "@/component-library/utilities/stylex";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import React, {
    forwardRef,
    HTMLAttributes,
    ReactNode,
    useMemo,
    useRef,
    useState,
} from "react";
import { useIsomorphicLayoutEffect } from "usehooks-ts";

interface FormEntryProps extends HTMLAttributes<HTMLDivElement> {
    label: ReactNode;
    description?: string;
    errorMessage?: string;
    width?: number;
    flexGrowFactor?: number;
    alignLabelRightOfField?: boolean;
    children: ReactNode;
    labelClassName?: string;
}

/**
 * Wraps an input field with a label and description. Provides description in a
 * popper on hover and click events inside the field.
 * @param data - The data for the form entry.
 * @param restProps - Other customisations to the [FormEntry] wrapper
 *   component.
 * @param inputHandleRef - Provides access to utility functions for the form
 *   entry.
 * @constructor
 */
export const FormEntry = forwardRef<HTMLDivElement, FormEntryProps>(
    (
        {
            children,
            label,
            description,
            errorMessage,
            width,
            flexGrowFactor,
            alignLabelRightOfField = false,
            className,
            style,
            labelClassName,
            ...restProps
        },
        refIn
    ) => {
        const labelRef = useRef<HTMLLabelElement | null>(null);

        const [contentWidth, setContentWidth] = useState(0);

        useIsomorphicLayoutEffect(() => {
            /**
             * Observe size changes in the label which also wraps the input
             * field.
             */

            if (!labelRef.current) {
                return;
            }

            const observer = new ResizeObserver(() => {
                setContentWidth(labelRef.current?.clientWidth ?? 0);
            });

            observer.observe(labelRef.current);

            return () => observer.disconnect();
        }, [labelRef, labelRef.current?.clientWidth]);

        const fieldWithLabel = useMemo(() => {
            if (alignLabelRightOfField) {
                return (
                    <label
                        ref={labelRef}
                        className={clsx(checkboxLabelStyle, labelClassName)}
                    >
                        {children}
                        <div>{label}</div>
                    </label>
                );
            }

            return (
                <label
                    ref={labelRef}
                    className={clsx(labelStyle, labelClassName)}
                >
                    {label}
                    {children}
                </label>
            );
        }, [alignLabelRightOfField, label, children]);

        return (
            <div
                ref={refIn}
                className={clsx(formEntryStyle, className)}
                style={stylex(
                    assignInlineVars({
                        width: `${width}px`,
                        flexGrow: `${flexGrowFactor}`,
                    }),
                    style
                )}
                {...restProps}
            >
                {description !== undefined ? (
                    <Popper
                        popperContents={
                            <div
                                className={formEntryPopperContents}
                                style={assignInlineVars({
                                    width: `${contentWidth}px`,
                                })}
                            >
                                {(errorMessage?.trim().length ?? 0) > 0 && (
                                    <div className={errorMessageTextStyle}>
                                        {errorMessage}
                                    </div>
                                )}
                                {description}
                            </div>
                        }
                        // When the field is in focus, allow to show the popper
                        // on hover
                        triggerOnHover={true}
                        // When the field is in focus, allow to show the popper
                        // on click
                        triggerOnMouseDown={true}
                    >
                        {fieldWithLabel}
                    </Popper>
                ) : (
                    fieldWithLabel
                )}
            </div>
        );
    }
);
FormEntry.displayName = "FormEntry";
