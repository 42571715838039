"use client";

import { FontAwesomeIcon } from "@/component-library/components/media/iconography/FontAwesomeIcon";
import { tagStyle } from "@/component-library/components/text/tags/tag/TagStyles.css";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { theme } from "@/component-library/themes/theme.css";
import { stylex } from "@/component-library/utilities/stylex";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import React, {
    forwardRef,
    HTMLAttributes,
    useImperativeHandle,
    useMemo,
    useRef,
} from "react";

interface TagProps extends HTMLAttributes<HTMLDivElement> {
    type?: "missing" | "present";
    color?: string;
}

/**
 * Tags capture concisely essential information about something, for instance,
 * capacity for a meeting room or a facility. Group them together with the
 * [TagGroup] component and add separators with the [TagGroupSeparator].
 */
export const Tag = forwardRef<HTMLDivElement, TagProps>(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (
        { children, type, color: _color, className, style, ...restProps },
        refIn
        // eslint-disable-next-line sonarjs/cognitive-complexity
    ) => {
        const tagRef = useRef<HTMLDivElement | null>(null);

        const color = useMemo(() => {
            if (_color) {
                return _color;
            }

            switch (type ?? "present") {
                case "missing": {
                    return theme.colors.semantic.warning.default;
                }
                case "present": {
                    return theme.colors.text.primary;
                }
            }
        }, [_color, type]);

        const borderColor = useMemo(() => {
            switch (type ?? "present") {
                case "missing": {
                    return theme.colors.semantic.warning.default;
                }
                case "present": {
                    return theme.colors.surface.outline;
                }
            }
        }, [type]);

        useImperativeHandle(refIn, () => tagRef.current!, [tagRef]);

        return (
            <div
                ref={tagRef}
                className={clsx(tagStyle, className)}
                style={stylex(
                    assignInlineVars({
                        color,
                        borderColor: borderColor,
                        borderWidth:
                            type === "missing"
                                ? `${Magnitudes.borderWidthInPx.l}px`
                                : undefined,
                    }),
                    style
                )}
                {...restProps}
            >
                {type === "missing" && (
                    <FontAwesomeIcon icon={faXmarkCircle} fontSize={1.7} />
                )}{" "}
                {children}
            </div>
        );
    }
);
Tag.displayName = "Tag";
