export class Magnitudes {
    static readonly blurInPx = {
        // in px because blur should always be the same regardless of any user
        // adjustments to the font size.
        xxs: 2.5,
        xs: 7.5,
        s: 15,
        m: 30,
        l: 60,
        xl: 120,
    };

    static readonly borderWidthInPx = {
        s: 1,
        m: 1.5,
        l: 2,
        xl: 2.5,
    };

    static readonly content = {
        imageBorderPaddingInRem: 0.5,
        pageContent: {
            maxWidthWithSidebarInPx: 1140,
            sidebarMinWidthInPx: 360,
            sidebarWidthInPx: 320,
        },
        collapsedPanelMinHeight: 15, // in rem
        maxDialogWidthInRem: 50, // in rem
        filteringPopupOptimalWidth: 35, // in rem
        inlineMapIdealHeightInRem: 30, // in rem
        maxDefaultContentWidth: 120, // in rem
        maxInfoPopperHeight: 8, // in rem
        maxMegaMenuWidth: 90, // in rem
        maxNaturalContentWidth: 80, // in rem
        maxMediumContentWidth: 100, // in rem
        maxTooltipWidth: 50, // in rem
        minButtonWidth: 12, // in rem
        minTooltipWidth: 20, // in rem
        readableWidthInCh: 90, // in ch
        sliderHeight: 1.8, // in rem
        toolbarFilterChipHeight: 4, // in rem
    };

    static readonly durationsInS = {
        xxxs: 0.025,
        xxs: 0.05,
        xs: 0.1,
        s: 0.15,
        m: 0.25,
        l: 0.4,
        xl: 0.6,
        xxl: 0.9,
        xxxl: 1.3,
    };

    static readonly baseFontSizeInPx = 9.5;

    static readonly fontInRem = {
        xxs: 1,
        xs: 1.2,
        s: 1.32,
        m: 1.4,
        l: 1.63,
        xl: 1.8,
        xxl: 2.2,
        xxxl: 2.6,
        xxxxl: 3.2,
        xxxxxl: 4.2,
    };

    static readonly lineHeight = {
        s: 1.3,
        m: 1.45,
        l: 1.6,
        xl: 1.8,
    };

    static readonly radiiInPx = {
        xxs: 2,
        xs: 4,
        s: 6,
        m: 8,
        l: 12,
        round: 999999,
    };

    static readonly spacingInRem = {
        xxxxs: 0.25,
        xxxs: 0.5,
        xxs: 0.85,
        xs: 1,
        s: 1.3,
        m: 1.6,
        l: 2.4,
        xl: 3.1,
        xxl: 3.5,
        xxxl: 4,
        xxxxl: 4.4,
        xxxxxl: 8.3,
    };

    private constructor() {}
}
