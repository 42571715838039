"use client";

import { NoValue } from "@/common/domain/entities/typing/NoValue";
import { useAnimation } from "@/component-library/animation/useAnimation";
import { iconButtonRecipe } from "@/component-library/components/buttons/icon-button/IconButtonStyles.css";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { VariantProps } from "@/component-library/domain/entities/VariantProps";
import { theme } from "@/component-library/themes/theme.css";
import { primaryBlueColor } from "@/component-library/themes/themes/lightTheme";
import { stylex } from "@/component-library/utilities/stylex";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import Color from "color";
import React, {
    FunctionComponent,
    HTMLAttributes,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";

interface IconButtonProps
    extends Omit<HTMLAttributes<HTMLButtonElement>, "title">,
        VariantProps<"filled" | "iconOnly" | "nonStyled" | "filledSmall"> {
    label: string | NoValue;
    children: React.ReactNode;
    disabled?: boolean;
    size?: "small" | "medium" | "large" | "inherit";
    accentColor?: Color;
    highlightColor?: Color;
    spacing?: number; // in rem
}

/**
 * A multi-use button for icons. Use variants to tailor it for specific uses.
 * If use is not covered, consider creating a new variants if the styles are
 * general enough, or creating a new component with [styled(IconButton)``] in
 * the component-library. Only in special one-off cases, create a component not
 * intended to be shared.
 * @param reportUIEvent - Reports UI events to analytics
 * @param onClick - What happens on click events.
 * @param label - Semantic description that describes what interacting with it
 *   will do.
 * @param children - The icon. Image, graphic, etc.
 * @param size
 * @param accentColor - What the primary color of the button should be.
 *   Affects fill when button is filled, the icon color when just an icon. The
 *   non-accent color is either off-white or off-black depending on the
 *   luminosity of the button. **Use only colours that have high or low
 *   luminosity.**
 * @param highlightColor - What color hover etc. effects will use. If not
 *   defined, takes a lighter and more saturated color of the accent, or uses
 *   default colors.
 * @param restProps - Other [IconButton] customisations.
 */
export function IconButton(
    this: FunctionComponent,
    {
        label,
        children,
        variant = "filled",
        disabled = false,
        size = "medium",
        accentColor = primaryBlueColor,
        highlightColor,
        onClick,
        style,
        className,
        ...restProps
    }: IconButtonProps
) {
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const { context, scopedTimeline } = useAnimation(buttonRef, {
        defaults: { duration: Magnitudes.durationsInS.s, ease: "easeOut" },
    });

    const [isHovering, setIsHovering] = useState(false);

    accentColor = disabled
        ? accentColor.lighten(0.3).desaturate(0.9)
        : accentColor;

    const { fontSize } = useMemo(() => {
        // These are one step larger than set for the icon.

        // eslint-disable-next-line @typescript-eslint/no-shadow
        let fontSize: string;

        switch (size) {
            case "large": {
                fontSize = `${Magnitudes.fontInRem.l}rem`;
                break;
            }
            case "medium": {
                fontSize = `${Magnitudes.fontInRem.m}rem`;
                break;
            }
            case "small": {
                fontSize = `${Magnitudes.fontInRem.s}rem`;
                break;
            }
            case "inherit": {
                fontSize = "inherit";
            }
        }

        if (variant === "iconOnly") {
            return { fontSize, padding: `0` };
        }

        return { fontSize };
    }, [size, variant]);

    useLayoutEffect(() => {
        if (disabled) {
            return;
        }

        context.add(() => {
            if (isHovering) {
                if (variant === "filled" || variant === "filledSmall") {
                    scopedTimeline.to(buttonRef.current, {
                        backgroundColor: (
                            highlightColor ??
                            accentColor.lighten(0.25).saturate(0.2)
                        ).toString(),
                    });
                } else {
                    scopedTimeline.to(buttonRef.current, {
                        color: (
                            highlightColor ??
                            accentColor.lighten(0.3).saturate(0.2)
                        ).toString(),
                    });
                }

                scopedTimeline.set(
                    buttonRef.current,
                    {
                        scale: 1.15,
                    },
                    "<"
                );
            } else {
                if (variant === "filled" || variant === "filledSmall") {
                    scopedTimeline.to(buttonRef.current, {
                        backgroundColor: accentColor.toString(),
                    });
                } else {
                    scopedTimeline.to(buttonRef.current, {
                        color: accentColor.toString(),
                    });
                }

                scopedTimeline.set(
                    buttonRef.current,
                    {
                        scale: 1,
                    },
                    "<"
                );
            }
        });
    }, [
        buttonRef,
        accentColor,
        context,
        highlightColor,
        scopedTimeline,
        isHovering,
        variant,
        disabled,
    ]);

    return (
        <button
            ref={buttonRef}
            className={clsx(iconButtonRecipe({ variant }), className)}
            style={stylex(
                {
                    ...assignInlineVars({
                        fontSize,

                        cursor: disabled ? "default" : "pointer",

                        ...(variant === "iconOnly"
                            ? {
                                  color: accentColor.toString(),
                              }
                            : {}),
                        ...(variant === "filled" || variant === "filledSmall"
                            ? {
                                  color: `${
                                      accentColor.l() >= 0.5
                                          ? theme.colors.basic.white
                                          : theme.colors.basic.black
                                  }`,
                                  backgroundColor: accentColor.toString(),
                              }
                            : {}),
                    }),
                },
                style
            )}
            title={label ?? undefined}
            onClick={onClick}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            {...restProps}
        >
            {children}
        </button>
    );
}
