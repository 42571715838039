import { flexStyle } from "@/component-library/components/organising-content/flex/FlexStyles.css";
import { Magnitudes } from "@/component-library/constants/Magnitudes";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import React, { forwardRef, HTMLAttributes, ReactNode } from "react";

interface FlexProps
    extends HTMLAttributes<HTMLDivElement>,
        Pick<
            React.CSSProperties,
            "justifyContent" | "alignItems" | "flexBasis" | "gap" | "flexWrap"
        > {
    direction: "row" | "column" | "row-reverse" | "column-reverse";
    children?: ReactNode;
    rowGap?: number;
    columnGap?: number;
}

/**
 * Creates a flex container with appropriate flow direction.
 * @param children
 * @param direction
 * @param justifyContent - Value for justify-content.
 * @param alignItems - Value for align-items.
 * @param flexBasis - Value for flex-basis.
 * @param gap - Gap between elements.
 * @param flexWrap
 * @param style - Other custom div styles.
 * @param restProps - Other div customisations.
 * @constructor
 */
const Flex = forwardRef<HTMLDivElement, FlexProps>(
    (
        {
            children,
            direction,
            justifyContent = "flex-start",
            alignItems = "center",
            flexBasis = "initial",
            gap,
            rowGap,
            columnGap,
            flexWrap = "nowrap",
            className,
            style,
            ...restProps
        },
        refIn
    ) => {
        return (
            <div
                ref={refIn}
                className={clsx(flexStyle, className)}
                style={{
                    ...assignInlineVars({
                        flexDirection: direction,
                        justifyContent: justifyContent,
                        alignItems: alignItems,
                        flexBasis: `${flexBasis}`,
                        rowGap: `${gap ?? rowGap ?? Magnitudes.spacingInRem.s}rem`,
                        columnGap: `${gap ?? columnGap ?? Magnitudes.spacingInRem.s}rem`,
                        flexWrap: flexWrap,
                    }),
                    ...style,
                }}
                {...restProps}
            >
                {children}
            </div>
        );
    }
);
Flex.displayName = "Flex";

export default Flex;
