import {
    fontAwesomeIconSkeletonStyle,
    fontAwesomeIconStyle,
} from "@/component-library/components/media/iconography/FontAwesomeIconStyles.css";
import { stylex } from "@/component-library/utilities/stylex";
import {
    FontAwesomeIcon as _FontAwesomeIcon,
    FontAwesomeIconProps as _FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { clsx } from "clsx";
import { forwardRef } from "react";

interface FontAwesomeIconProps extends _FontAwesomeIconProps {
    fontSize?: number;
    skeleton?: boolean;
}

/**
 * Styles Font Awesome Icons and gives them a [size] prop for consistent
 * sizing.
 */
export const FontAwesomeIcon = forwardRef<SVGSVGElement, FontAwesomeIconProps>(
    (
        { color, fontSize, style, className, skeleton = false, ...restProps },
        refIn
    ) => {
        return (
            <_FontAwesomeIcon
                ref={refIn}
                className={clsx(
                    fontAwesomeIconStyle,
                    className,
                    skeleton && fontAwesomeIconSkeletonStyle
                )}
                style={stylex(
                    assignInlineVars({
                        color: color ?? "inherit",
                        fontSize: fontSize ? `${fontSize}rem` : "inherit",
                    }),
                    style
                )}
                {...restProps}
            />
        );
    }
);
FontAwesomeIcon.displayName = "FontAwesomeIcon";
