"use client";

import { useValueRef } from "@/component-library/animation/useValueRef";
import { useCallback, useEffect } from "react";
import { useWindowSize } from "usehooks-ts";

/**
 *  The hook relies on reference inequality between objects for client side
 * hooks that need to be re-rendered on viewport changes. Use [useScrollable]
 * to get access to the scroll offset of a parent scrolling view.
 * @returns The return value changes whenever either of the viewport/window
 *   dimensions change size or scale. So, you can use the object itself as a
 *   dependency for reacting to dimension changes, or either [windowWidth] or
 *   [windowHeight] properties of the object. **Note, that both change whenever
 *   either changes.** Use [useWindowSize] if this is not desired.
 */
export const useViewportDimensionsChanged = () => {
    const { width: windowWidth, height: windowHeight } = useWindowSize();
    const {
        ref: viewportDimensionsChangeIndicatorValueRef,
        set: setViewportDimensionsChangeIndicatorValue,
    } = useValueRef({});

    const dimensionsMayHaveChanged = useCallback(() => {
        setViewportDimensionsChangeIndicatorValue({});
    }, [setViewportDimensionsChangeIndicatorValue]);

    useEffect(() => {
        window.addEventListener("resize", dimensionsMayHaveChanged);
        window.visualViewport?.addEventListener(
            "resize",
            dimensionsMayHaveChanged
        );

        return () => {
            window.removeEventListener("resize", dimensionsMayHaveChanged);
            window.visualViewport?.removeEventListener(
                "resize",
                dimensionsMayHaveChanged
            );
        };
    }, [dimensionsMayHaveChanged]);

    return {
        indicator: viewportDimensionsChangeIndicatorValueRef.current,
        windowWidth,
        windowHeight,
    };
};
