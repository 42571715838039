"use client";

import { useCallback, useRef, useState } from "react";

/**
 * This hook helps solve the problem of accessing stale values in animation
 * callbacks. Provides a set-state dispatch function and a ref to the state,
 * always guaranteeing an up-to-date value. Adapted from:
 * https://gsap.com/resources/react-advanced#usestateref
 * @template TValue is the type of value(s) accepted.
 * @param initialValue of the ref.
 * @returns object with a ref to the value and a setter function. The setter
 * only accepts a new value of [TValue] type and never a function. The ref's
 * value is always up-to-date, so if you need previous state, just use the
 * ref. If you want to update a component whenever the state changes, you need
 * to use the [state].
 */
export function useValueRef<TValue>(initialValue: TValue) {
    const [value, setValue] = useState(initialValue);
    const valueRef = useRef(value);

    const setNewValue = useCallback(
        (newValue: TValue) => {
            setValue(newValue);
            valueRef.current = newValue;
        },
        [setValue]
    );

    return {
        state: valueRef.current,
        ref: valueRef,
        set: setNewValue,
    };
}
