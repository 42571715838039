import { Magnitudes } from "@/component-library/constants/Magnitudes";
import Color from "color";

export const blackColor = Color("#262627");
export const pitchBlackColor = Color("#000000");
export const whiteColor = Color("#FFFFFF");
export const primaryBlueColor = Color("#004CA3");
export const primaryBlueFadedColor = Color("#374f77");
export const primaryBlueDarkColor = Color("#003366");
export const greenColor = Color("#04A528");
export const redColor = Color("#dd0215");
export const orangeColor = Color("#F08200");

export const lightSurfaceColor = Color("#fdfdff");
export const mediumSurfaceColor = Color("#f8f9fc");
export const darkSurfaceColor = Color("#f2f3f9");
export const disabledSurfaceColor = Color("#6b6d7a");
export const loadingSurfaceColor = Color("#C9D1DFFF");

export const outlineColor = Color("#CED9E7");
export const lightOutlineColor = Color("#eceef1");

export const textColor = blackColor;
export const secondaryTextColor = Color("#4e4e5e");
export const tertiaryTextColor = Color("#7c7c8c");
export const lightDisabledTextColor = Color("#e0e1e3");
export const darkDisabledTextColor = Color("#868c94");

export const histogramBarColor = Color("#b6c0d2");
export const sliderColor = histogramBarColor.darken(0.1);
export const visualisationConnectionColor = Color("#D1DAE7");

/**
 * Theme for the app. Any other theme should use this one as the type, i.e.
 * [typeof lightTheme] so that all the properties are the same between themes.
 */
export const lightTheme = {
    colors: {
        basic: {
            primary: primaryBlueColor.toString(),
            secondary: redColor.toString(),
            white: whiteColor.toString(),
            black: blackColor.toString(),
            orange: orangeColor.toString(),
            red: redColor.toString(),
        },
        semantic: {
            error: {
                ultraLight: redColor.lighten(1.226).desaturate(0.4).toString(),
                veryLight: redColor.lighten(0.68).desaturate(0.4).toString(),
                lighter: redColor.lighten(0.2).toString(),
                default: redColor.toString(),
                darker: redColor.darken(0.2).toString(),
            },
            warning: {
                lighter: redColor.lighten(0.2).toString(),
                default: redColor.toString(),
                darker: redColor.darken(0.2).toString(),
            },
            success: {
                lighter: greenColor.lighten(0.2).toString(),
                default: greenColor.toString(),
                darker: greenColor.darken(0.2).toString(),
            },
            facility: {
                lighter: redColor.lighten(0.4).toString(),
                default: redColor.lighten(0.3).toString(),
                darker: redColor.lighten(0.2).toString(),
            },
            capacity: {
                lighter: greenColor.lighten(0.2).toString(),
                default: greenColor.toString(),
                darker: greenColor.darken(0.2).toString(),
            },
            other: {
                lighter: blackColor.lighten(2.5).toString(),
                default: blackColor.lighten(0.3).toString(),
                darker: blackColor.lighten(0.2).toString(),
            },
            interactive: {
                veryLightDesaturated: primaryBlueColor
                    .lighten(0.65)
                    .desaturate(0.7)
                    .toString(),
                lighter: primaryBlueColor
                    .lighten(0.4)
                    .desaturate(0.3)
                    .toString(),
                default: primaryBlueColor.toString(),
                darker: primaryBlueColor.darken(0.2).toString(),
                transparent: primaryBlueColor.alpha(0).toString(),
                disabledInteractive: primaryBlueColor
                    .lighten(0.95)
                    .desaturate(0.8)
                    .toString(),
            },
            secondaryInteractive: {
                veryLight: primaryBlueFadedColor
                    .lighten(0.5)
                    .desaturate(0.2)
                    .toString(),
                lighter: primaryBlueFadedColor.lighten(0.08).toString(),
                default: primaryBlueFadedColor.toString(),
                darker: primaryBlueFadedColor.darken(0.2).toString(),
            },
        },
        text: {
            primary: textColor.toString(),
            secondary: secondaryTextColor.toString(),
            tertiary: tertiaryTextColor.toString(),
            darkDisabled: darkDisabledTextColor.toString(),
            lightDisabled: lightDisabledTextColor.toString(),
        },
        surface: {
            ultraLightSurface: {
                o30: whiteColor.alpha(0.3).toString(),
                o50: whiteColor.alpha(0.5).toString(),
                o80: whiteColor.alpha(0.8).toString(),
                o90: whiteColor.alpha(0.9).toString(),
                o100: whiteColor.toString(),
            },
            lightSurface: {
                o50: lightSurfaceColor.alpha(0.5).toString(),
                o80: lightSurfaceColor.alpha(0.8).toString(),
                o90: lightSurfaceColor.alpha(0.9).toString(),
                o100: lightSurfaceColor.toString(),
            },
            mediumSurface: {
                o50: mediumSurfaceColor.alpha(0.5).toString(),
                o80: mediumSurfaceColor.alpha(0.8).toString(),
                o90: mediumSurfaceColor.alpha(0.9).toString(),
                o100: mediumSurfaceColor.toString(),
            },
            darkSurface: {
                o20: darkSurfaceColor.alpha(0.2).toString(),
                o50: darkSurfaceColor.alpha(0.7).toString(),
                o80: darkSurfaceColor.alpha(0.8).toString(),
                o90: darkSurfaceColor.alpha(0.9).toString(),
                o100: darkSurfaceColor.toString(),
            },
            disabledSurface: {
                o50: disabledSurfaceColor.alpha(0.5).toString(),
                o80: disabledSurfaceColor.alpha(0.8).toString(),
                o90: disabledSurfaceColor.alpha(0.9).toString(),
                o100: disabledSurfaceColor.toString(),
            },
            loadingSurface: {
                startAndEndAnimationAtThisColor: loadingSurfaceColor.toString(),
                animateToThisColor: loadingSurfaceColor
                    .lighten(0.14)
                    .toString(),
            },
            outline: outlineColor.toString(),
            lightOutline: lightOutlineColor.toString(),
        },
        layoutSurface: {
            invertedSurface: {
                o20: mediumSurfaceColor.darken(0.8).alpha(0.2).toString(),
                o50: mediumSurfaceColor.darken(0.8).alpha(0.7).toString(),
                o80: mediumSurfaceColor.darken(0.8).alpha(0.8).toString(),
                o90: mediumSurfaceColor.darken(0.8).alpha(0.9).toString(),
                o100: mediumSurfaceColor
                    .darken(0.5)
                    .desaturate(0.75)
                    .toString(),
            },
        },
        charts: {
            histogramBarColor: histogramBarColor.toString(),
            sliderColor: histogramBarColor.toString(),
            sliderDisabledColor: sliderColor.toString(),
            rangeColor: primaryBlueColor.toString(),
            sliderTickColor: sliderColor.lighten(0.3).toString(),
            connectionColor: visualisationConnectionColor.toString(),
        },
    },
    shadows: {
        subtle: `0px 1.5px 5px 0px ${blackColor.alpha(0.04).toString()}`,
        small: `0px 2.5px 10px 0px ${blackColor.alpha(0.05).toString()}`,
        diffuse: `0px 10px 40px 0px rgba(27, 47, 69, 0.05)`,
        interactive: `0px 2.5px 10px 0px ${primaryBlueColor.alpha(0.08).toString()}, 0px 1px 3px 0px ${primaryBlueColor
            .alpha(0.03)
            .toString()}`,
    },
    styles: {
        surfaceOutline: {
            light: `${Magnitudes.borderWidthInPx.s}px solid ${lightOutlineColor.toString()}`,
            medium: `${Magnitudes.borderWidthInPx.s}px solid ${outlineColor.toString()}`,
            dark: `${Magnitudes.borderWidthInPx.s}px solid ${outlineColor.darken(0.1).toString()}`,
            interactive: `${Magnitudes.borderWidthInPx.m}px solid ${primaryBlueColor.toString()}`,
        },
        errorOutline: {
            light: `1.5px solid ${redColor.lighten(0.9).toString()}`,
            medium: `1.5px solid ${redColor.lighten(0.25).toString()}`,
            dark: `1.5px solid ${redColor.toString()}`,
        },
    },
};
