import { Magnitudes } from "@/component-library/constants/Magnitudes";

/**
 * Converts from rem to px. **Prefer [useFontSizeConverter] as it memoizes the
 * conversion function.**
 * @param rem - The number of rem to convert to px.
 */
export const remToPx = (rem: number) => {
    return rem * Magnitudes.baseFontSizeInPx;
};

/**
 * Converts from px to rem. **Prefer [useFontSizeConverter] as it memoizes the
 * conversion function.**
 * @param px - The number of px to convert to rem.
 */
export const pxToRem = (px: number) => {
    return px / Magnitudes.baseFontSizeInPx;
};
